export * from './auth.guard.service';
export * from './auth.service';

import {AuthGuard } from './auth.guard.service';
import {AuthService} from './auth.service';


export const SERVICES = [
    AuthGuard,
    AuthService
];

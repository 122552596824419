export * from './menu.service';
export * from './station.service';
export * from './request.service';



import {GeneralService} from './general.service';
import {MenuService} from './menu.service';
import {StationService} from './station.service';
import {RequestService} from './request.service';



export const SERVICES = [
    MenuService,
    StationService,
    RequestService,
    GeneralService,
];

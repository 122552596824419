import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] = [
  // {
  //   title: 'Menú',
  //   group: true,
  // },
  // {
  //   title: 'Inicio',
  //   icon: 'home-outline',
  //   link: '/pages/inicio',
  //   home: true,
  // },
  // {
  //   title: 'Información',
  //   group: true,
  // },
  // {
  //   title: 'Información General',
  //   icon: 'file-text-outline',
  //     children: [
  //       {
  //         title: '1. Sector Cordillera',
  //         children: [
  //           {
  //             title: '- Subsector Mina Rajo',
  //             link: '/pages/informacionGeneral/sector1',
  //             queryParams: { sector: 3 },
  //           },
  //           {
  //             title: '- Subsector Industrial',
  //             link: '/pages/informacionGeneral/sector2',
  //             queryParams: { sector: 4 },
  //           },
  //         ],
  //       },
  //       {
  //         title: '2. Sector Valle',
  //         link: '/pages/informacionGeneral/sector3',
  //         queryParams: { sector: 2 },
  //       },
  //     ],
  // },
  // {
  //   title: 'Datos históricos',
  //   icon: 'list-outline',
  //   link: '/pages/historico',
  // },
  // {
  //   title: 'Sistema de Alertas',
  //   icon: 'alert-triangle-outline',
  //   link: '/pages/alerts',
  //   children: [
  //     {
  //       title: '1. Sector Cordillera',
  //       children: [
  //         {
  //           title: '- Subsector Mina Rajo',
  //           link: '/pages/alerts/sector',
  //           queryParams: { sector: 3 },
  //         },
  //         {
  //           title: '- Subsector Industrial',
  //           link: '/pages/alerts/sector2',
  //           queryParams: { sector: 4 },
  //         },
  //       ],
  //     },
  //     {
  //       title: '2. Sector Valle',
  //       link: '/pages/alerts/sector3',
  //       queryParams: { sector: 2 },
  //     },
  //   ],
  // },
  // {
  //   title: 'Pronóstico',
  //   icon: 'pantone-outline',
  //     children: [
  //       {
  //         title: '1. Sector Cordillera',
  //         children: [
  //           {
  //             title: '- Subsector Mina Rajo',
  //             link: '/pages/pronostico/subsector',
  //             queryParams: { sector: 3 },
  //           },
  //         ],
  //       },
  //       {
  //         title: '2. Sector Valle',
  //         link: '/pages/pronostico/sector',
  //         queryParams: { sector: 2 },
  //       },
  //     ],
  // },
  // {
  //   title: 'Otros',
  //   group: true,
  // },
  // {
  //   title: 'Control de estaciones',
  //   icon: 'toggle-left-outline',
  //   link: '/pages/control-estaciones',
  // },
  // {
  //   title: 'Ayuda',
  //   icon: 'alert-circle-outline',
  //   link: '/pages/ayuda',
  // },
];


export let menu_dynamic: NbMenuItem[] = [];
export let menu_Admin: NbMenuItem[] = [];


export function setMenu(modules: NbMenuItem[]): void {
  // CONSTRUYE EL MENÚ A PARTIR DE UN VECTOR
  clearMenu();
  modules.forEach( (element) => {
    menu_dynamic.push(element);
  });
}

export function setMenuAdmin(modulesAdmin: NbMenuItem[]): void {
  // CONSTRUYE EL MENÚ A PARTIR DE UN VECTOR
  clearMenu();
  modulesAdmin.forEach( (element) => {
    menu_Admin.push(element);
  });
}


export function clearMenu(): void {
  menu_dynamic.length = 0;
  menu_Admin.length = 0;
}





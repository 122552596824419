import {catchError, map, tap} from 'rxjs/operators';
import {CanActivate, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';

import {AuthService} from '../auth/services';
import { data_source } from '../../environments/data.source';
import { saveAs } from 'file-saver';

let base_url = data_source.url;
let ruta = base_url+'data/';
let ruta2 = base_url+'actions/';

// if(!data_source.local){
//    ruta = 'http://localhost:9767/data/';
    // ruta = 'https://ip135.ip-167-114-242.eu:18000/data/';
    // ruta2 = 'https://ip135.ip-167-114-242.eu:18000/actions/';
    // ruta = 'http://localhost:9876/data/';
    // ruta2 = 'http://localhost:9876/actions/';

    // ruta = 'https://ip135.ip-167-114-242.eu:35129/data/';
    // ruta2 = 'https://ip135.ip-167-114-242.eu:35129/actions/';
// }

const httpOptions = { headers: new HttpHeaders({
'Content-Type': 'application/json' ,
}) };

export class Subscriber {
    public http: Observable<any>;

    constructor(http: Observable<any>, protected router:Router) {
        this.http = http;
    }

    static make(httpc: HttpClient, url: string, params: any, op: string = 'error', router: Router): Subscriber {
        
        const req = httpc.post<Respuesta>(ruta + url, params, httpOptions);
        // console.log(router);
        const sus = new Subscriber(req, router);
        sus.pipe(op);

        return sus;
    }
    
    static makeActions(httpc: HttpClient, url: string, params: any, op: string = 'error', router: Router): Subscriber {
        const req = httpc.post<Respuesta>(ruta2 + url, params, httpOptions);
        const sus = new Subscriber(req, router);
//        sus.pipe(op);

        return sus;
    }

    static makeCsv(httpc: HttpClient, url: string, params: any, op: string = 'error', router: Router): Subscriber {
        const req = httpc.post<Respuesta>(ruta + url, params, Object.assign({
            responseType: 'text',
        }, httpOptions));
        const sus = new Subscriber(req, router);
        sus.pipeCsv(op);

        return sus;
    }

    private map(d: Respuesta) {
        // console.log('map', d);
        // alert("aaa");
        if (d.session === false) {
            this.router.navigate(['/auth/login']);
            return -1;
        } else if (!d.ok) {
            return false;
        } 
        return d.data;
    }

    pipe(op: string): Observable<any> {
        this.http = this.http.pipe(
            map( d=> this.map(d)),
            catchError(this.handleError<any>(op, false)),
        );
        return this.http;
    }

    pipeCsv(op: string): Observable<any> {
        this.http = this.http.pipe(
            catchError(this.handleError<any>(op, false)),
        );
        return this.http;
    }

    subscribe(a): any {
        return this.http.subscribe(a);
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    log(a) {
        alert(a);
    }
}

export class Respuesta {
    ok: boolean;
    session?: boolean;
    data: any;
}


@Injectable()
export class RequestService {

    constructor(private http: HttpClient,private router: Router, protected auth: AuthService) {}

    makeRequest(url: string, params: any) {
        // console.log(this.auth);
        let token:string = '';
        if(this.auth.gToken() != null){
            token = `&token=${this.auth.token}`; 
            //params.token = this.auth.token;
        }
        return Subscriber.make(this.http, url+token, params, `get ${url}`, this.router);
    }


    makeCsvRequest(url: string, params: any) {
        // console.log(this.auth);
        let token:string = '';
        if(this.auth.gToken() != null){
            token = `&token=${this.auth.token}`; 
            //params.token = this.auth.token;
        }
        return Subscriber.makeCsv(this.http, url+token, params, `csvGet ${url}`, this.router);
    }
    
    makeActionsRequest(url: string, params: any) {
        // console.log(this.auth);
        let token:string = '';
        if(this.auth.gToken() != null){
            token = `&token=${this.auth.token}`; 
            //params.token = this.auth.token;
        }
        return Subscriber.makeActions(this.http, url+token, params, `actions ${url}`, this.router);
    }

    downloadFile(id:string, name:string){
        let token:string = '';
        if(this.auth.gToken() != null){
            token = `&token=${this.auth.token}`; 
        }
        let url = ruta + `/download_document${token}&id=${id}`;
        // console.log('Ruta', url, name);
        let response = this.http.get(url, {responseType: 'blob'});
        response.subscribe( (res) => {
            let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
            saveAs(blob, name);
        });
    }

    downloadFicha(id: string, name:string){
      let token: string = '';
      if(this.auth.gToken() != null){
          token = `&token=${this.auth.token}`; 
      }
      let url = ruta + `/download_ficha${token}&id=${id}`;
      // console.log('Ruta', url, name);
      let response = this.http.get(url, {responseType: 'blob'});
      response.subscribe( (res) => {
          let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
          saveAs(blob, name);
      });
    } 

    downloadFolder(path:string){
        let name_split = path.split('/');
        let name:string = `folder_${name_split[name_split.length -1]}.7z`;

        let token:string = '';
        if(this.auth.gToken() != null){
            token = `&token=${this.auth.token}`; 
        }

        let url = ruta + `/download_folder${token}&path=${path.replace(/\//g, ';')}`;
        console.log('Ruta', url, name);
        let response = this.http.get(url, {responseType: 'blob'});
        response.subscribe( (res) => {
            let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
            saveAs(blob, name);
        });
    }
    

    getRuta() {
        return ruta;
    }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { RequestService, MenuService } from '../../../services';
import { AuthService } from '../../../auth/services';
import { GeneralService } from '../../../services/general.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed *ngIf="isMobileLayout">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <h5 style='color: white; text-align: center; margin-top: 30px;'>DAND <br> CALIDAD AIRE</h5>
        <ng-content select="nb-menu"></ng-content>
        <p class="closeSesion" (click)="logOut()"><nb-icon icon="undo-outline"></nb-icon>Cerrar sesión</p>
      </nb-sidebar>

      <nb-layout-column style="padding: 0px">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!-- <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer> -->
    </nb-layout>
  `,
  //   template: `
  //   <nb-layout windowMode>
  //     <nb-layout-header fixed>
  //       <ngx-header></ngx-header>
  //     </nb-layout-header>

  //     <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
  //       <ng-content select="nb-menu"></ng-content>
  //     </nb-sidebar>

  //     <nb-layout-column>
  //       <ng-content select="router-outlet"></ng-content>
  //     </nb-layout-column>

  //     <nb-layout-footer fixed>
  //       <ngx-footer></ngx-footer>
  //     </nb-layout-footer>
  //   </nb-layout>
  // `,
})
export class OneColumnLayoutComponent implements OnInit, OnDestroy {
  generalData = [];
  private generalService: Subscription;
  constructor(private req: RequestService, private auth: AuthService, public _General: GeneralService, private _Menu: MenuService, private router: Router) {}

  logOut() {
    // SE CIERRA LA SESIÓN
    this.req.makeActionsRequest('logout', {}).subscribe(d => {
        this.auth.logout();
    });

  }

  public isMobileLayout = false;
  ngOnInit() {
    this._Menu.vaciaMenu();
    window.onresize = () => this.isMobileLayout = window.innerWidth < 1200;
    // window.onresize = () => console.log(window.innerWidth);
    this.isMobileLayout = window.innerWidth < 1200;

    // OBTENEMOS LOS DATOS GENERICOS PARA EL USUARIO
    this.generalService = this._General.onGeneralUpdated().subscribe((data) => {
      if (data == null) {
        this.generalData = [];
      } else {
        this.generalData = data;
        // console.log(data.menu);
        this._Menu.getMenu(data.menu); // CREAMOS EL MENU A PARTIR DEL PUENTE SELECCIONADO
      
        if (data.user.id == 144) {
          this.router.navigateByUrl('/pages/alerts/sector?sector=3'); // MINA RAJO
        } else if (data.user.id == 145) {
          this.router.navigateByUrl('/pages/alerts/sector3?sector=2'); // SECTOR VALLE
        } else if (data.user.id == 156) { // Usuario tronaduras-mr
          this.router.navigateByUrl('/pages/pronostico/tronadura?sector=3'); // SECTOR MINA RAJO
        } 

        this.generalService.unsubscribe();
      }
    });

    this.generalData = [];
    this._General.getData();
  }

  ngOnDestroy() {
    if (this.generalService) { this.generalService.unsubscribe(); }
  }


}

import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from './menu.service';

import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {RequestService} from './request.service';
import { Observable } from 'rxjs';

@Injectable()

export class GeneralService {

  constructor(private router: Router,  private _Menu: MenuService, private request: RequestService) {}

  // VECTORES PARA GUARDAR RESPUESTA DE LA PETICIÓN
  private company: Company;
  private menu: Menu;
  private projects: Projects;
  private user: User;
  private vendor: Vendor;
  private prod: Boolean;


  private on_general_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onGeneralUpdated(): Observable<any> {
    return this.on_general_updated.asObservable();
  }

  // OBTENEMOS LOS DATOS
  getData() {
    // pedimos los datos
    this.request.makeRequest('init', {}).subscribe((d) => {
      // console.log('respuesta de downloads', d);
      // cuando los obtenemos, avisamos a los que se hayan
      // suscrito(en este caso solo el componente informes)
      // para actualizar los datos de descargas y los pinte
      // d = d.map( e =>  {e.id = parseInt(e.id);return e;}) //TIPO DE MAPEO
      this.on_general_updated.next(d);

      // this.company = d.company;
      // this.menu = d.menu;
      // this.projects = d.project;
      // console.log(d.user);
      this.user = d.user;
      // this.vendor = d.vendor;
      // this.prod = d.prod;
    });
  }


  // MÉTODOS PARA ACCEDER A LOS DATOS
  getCompany(): Company {
    return this.company;
  }

  getMenu(): Menu {
    return this.menu;
  }

  getProjects(): Projects {
    return this.projects;
  }

  getUser(): User {
    console.log(this.user);
    return this.user;
  }

  getVendor(): Vendor {
    return this.vendor;
  }

  getProd() {
    return this.prod;
  }



}

export interface Company {
  id: string;
  label: string;
  logo_max: string;
  logo_min: string;
  vendor_id: string;
}

export interface Menu {
  title: string;
  link: string;
  icon: string;
  expanded: string;
  children:
    {
      children: any;
      expanded: string;
      icon: string;
      link: string;
      title: string;
    }[];
}[];




export interface Projects {
  alerts: number;
  id: number;
  name: string;
  ini_date: string;
  location: string;
  nnodes: string;
  state: number;
  coord:
    {
      x: string;
      y: string;
    };
}[];


export interface User {
  // id: number;
  admin: number;
  email: string;
  name: string;
}



export interface Vendor {
  id: string;
  label: string;
  logo_max: string;
  logo_min: string;
  web_site: string;
}

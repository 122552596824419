import { Injectable, Input } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {RequestService} from './request.service';
import { Observable, Subject } from 'rxjs';


@Injectable()


export class StationService {

  constructor(private request: RequestService) {}




// SE OBTIENEN TODAS LAS ESTACIONES
private on_allStations_updated: BehaviorSubject<any> = new BehaviorSubject(null);

public onAllStationsUpdated(): Observable<any> {
  return this.on_allStations_updated.asObservable();
}

// DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
getAllStations() {
    this.request.makeRequest('all_stations', {}).subscribe((d) => {
      this.on_allStations_updated.next(d);
    });
}







// SE OBTIENEN TODAS LAS ESTACIONES POR SECTOR
  private on_stations_sector_updated: Subject<any> = new Subject();

  public onStationsSectorUpdated(): Observable<any> {
    return this.on_stations_sector_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getStationsSector(idSector) {
      this.request.makeRequest('stations', {sector: idSector}).subscribe((d) => {
        this.on_stations_sector_updated.next(d);
      });
  }


  // SE OBTIENEN TODAS LAS ESTACIONES POR SECTOR PARA TRONADURAS
  private on_stations_tronaduras_updated: Subject<any> = new Subject();

  public onStationsSectorTronadurasUpdated(): Observable<any> {
    return this.on_stations_tronaduras_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getStationsTronadurasSector(idSector) {
      this.request.makeRequest('stations', {sector: idSector}).subscribe((d) => {
        this.on_stations_tronaduras_updated.next(d);
      });
  }


  // SE OBTIENEN TODAS LAS ESTACIONES POR SECTOR PARA ALERTAS
  // private on_stations_sector_alerts_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  // public onStationsSectorAlertsUpdated(): Observable<any> {
  //   return this.on_stations_sector_alerts_updated.asObservable();
  // }

  // // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  // getStationsSectorAlert(idSector) {
  //     this.request.makeRequest('stations', {sector: idSector}).subscribe((d) => {
  //       this.on_stations_sector_alerts_updated.next(d);
  //     });
  // }





// SE OBTIENEN LOS DATOS DE LA ESTACIÓN SELECCIONADA
  private on_station_updated: Subject<any> = new Subject();

  public onStationUpdated(): Observable<any> {
    return this.on_station_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getDataStation(idStation, date_ini, date_end, period = null) {
      this.request.makeRequest('data', {id: idStation, date_ini: date_ini, date_end: date_end, period: period}).subscribe((d) => {
        // console.log('DATA: ', d);
        this.on_station_updated.next(d);
      });
  }




// SE OBTIENEN LOS DATOS DE LA ESTACIÓN SELECCIONADA PARA EL HISTORICO
private on_historico_updated: Subject<any> = new Subject();

public onHistoricoUpdated(): Observable<any> {
  return this.on_historico_updated.asObservable();
}

// DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
getHistoricoStation(idStation, date_ini, date_end) {
    this.request.makeRequest('hist_data', {id: idStation, date_ini: date_ini, date_end: date_end}).subscribe((d) => {
      this.on_historico_updated.next(d);
    });
}






// GENERAR INFORME DE DATOS
private on_informe_updated: BehaviorSubject<any> = new BehaviorSubject(null);

public onInformeUpdated(): Observable<any> {
  return this.on_informe_updated.asObservable();
}

// DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
getInformeStation(idStation, date_ini, date_end) {
  window.open(this.request.getRuta() + `/data_download&id=${idStation}&date_ini=${date_ini}&date_end=${date_end}&dtoken=t1571302848200`, '_blank');
}







  // SE OBTIENEN LOS DATOS DE ALERTAS PARA LAS GRÁFICAS DE LA ESTACIÓN SELECCIONADA
  private on_stationDataAlert_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onStationDataAlertUpdated(): Observable<any> {
    return this.on_stationDataAlert_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getDataAlertStation(idStation, date_ini, date_end) {
    this.request.makeRequest('alerts_data', {id: idStation, date_ini: date_ini, date_end: date_end}).subscribe((d) => {
      this.on_stationDataAlert_updated.next(d);
    });
  }




  // TABLA ALERTAS
  private on_tableAlerts_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onTableAlertsUpdated(): Observable<any> {
    return this.on_tableAlerts_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getTableAlertsStation(idStation) {
    this.request.makeRequest('alerts', {id: idStation}).subscribe((d) => {
      this.on_tableAlerts_updated.next(d);
    });
  }







  // PRONÓSTICO
  private on_pronostico_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onPronosticoUpdated(): Observable<any> {
    return this.on_pronostico_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getPronosticoStation(idStation, date) {
    this.request.makeRequest('forecast', {id: idStation, date: date}).subscribe((d) => {
      this.on_pronostico_updated.next(d);
    });
  }


  // PRONÓSTICO TRONADURAS
  private on_pronostico_tronaduras_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onPronosticoTronadurasUpdated(): Observable<any> {
    return this.on_pronostico_tronaduras_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getPronosticoStationTronaduras(idStation, date) {
    this.request.makeRequest('forecast', {id: idStation, date: date}).subscribe((d) => {
      this.on_pronostico_tronaduras_updated.next(d);
    });
  }



  // PRONÓSTICO MINA RAJO
  private on_pronosticoMR_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onPronosticoMRUpdated(): Observable<any> {
    return this.on_pronosticoMR_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getPronosticoMRStation(idStation, date) {
    this.request.makeRequest('forecast', {id: idStation, date: date}).subscribe((d) => {
      this.on_pronosticoMR_updated.next(d);
    });
  }







  // PRONÓSTICO MINA RAJO
  private on_contact_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onContactUpdated(): Observable<any> {
    return this.on_contact_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  getContactStation(name, subject, email, description, phone) {
    this.request.makeRequest('contact_form', {name: name, subject: subject, email: email, description: description, phone: phone}).subscribe((d) => {
      this.on_contact_updated.next(d);
    });
  }














  // ENABLED / DISABLED STATIONS
  private on_enabled_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onEnabledUpdated(): Observable<any> {
    return this.on_enabled_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  updateEnabledStation(idStation, enabled) {
    this.request.makeRequest('change_st_enabled', {id: idStation, enabled: enabled}).subscribe((d) => {
      this.on_enabled_updated.next(d);
    });
  }





  // ENABLED / DISABLED STATIONS
  private on_saveNote_updated: BehaviorSubject<any> = new BehaviorSubject(null);

  public onSaveNoteUpdated(): Observable<any> {
    return this.on_saveNote_updated.asObservable();
  }

  // DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
  saveNoteAlertStation(idAlert, text) {
    this.request.makeRequest('update_alert_note', {id: idAlert, note: text}).subscribe((d) => {
      this.on_saveNote_updated.next(d);
    });
  }













// SE OBTIENEN LOS DATOS PARA GENERAR EL INFORME
private on_informeGenerate_updated: BehaviorSubject<any> = new BehaviorSubject(null);

public onInformeGUpdated(): Observable<any> {
  return this.on_informeGenerate_updated.asObservable();
}

// DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
getInformeGenerateStations(start_date, end_date, type, alert) {
    this.request.makeRequest('informe_data', { start_date: start_date, end_date: end_date, type: type, alert: alert}).subscribe((d) => {
      this.on_informeGenerate_updated.next(d);
    });
}




// SE OBTIENEN LOS DATOS PARA GENERAR EL INFORME DE ALERTA
private on_informeAlert_updated: BehaviorSubject<any> = new BehaviorSubject(null);

public onInformeAlertUpdated(): Observable<any> {
  return this.on_informeAlert_updated.asObservable();
}

// DEVOLVEMOS LOS DATOS DEL PUENTE SELECCIONADO A PARTIR DEL ID DEL PUENTE
getInformeAlertStations(start_date, end_date, type, alert) {
    // this.request.makeRequest('informe_data', { start_date: start_date, end_date: end_date, type: type, alert: alert}).subscribe((d) => {
    //   this.on_informeAlert_updated.next(d);
    // });
    window.open(this.request.getRuta() + `download_alert_informe&alert=${alert}&dtoken=t1571302848200`, '_blank');
}

getInformePronostico(mrf, start_date, end_date){

  window.open(this.request.getRuta() + `download_informe_pronostico&mrf=${mrf}&dtoken=t1571302848200&start_date=${start_date}&end_date=${end_date}`, '_blank');

}











}



// export interface DataProject {
//   prod: string;
//   project: {
//     co_id: number;
//     gps_lat: string;
//     gps_lon: string;
//     id: number;
//     ini_date: string;
//     end_date: string;
//     location: string;
//     initial_state_date: string;
//     name: string;
//     nnodes: number;
//     pl_id: string;
//     string_identifier: string|null;
//     simulations: number;
//   };
//   sections: {
//     id: number;
//     image: string;
//     lX: number;
//     lX2: number;
//     lY: number;
//     lY2: number;
//     label: string;
//     position: string;
//     position_label: string|null;
//     stateSection: number|null;
//     ubi_section: string;
//     nodes: {
//       despl_type: string|null;
//       id: number;
//       lX: number;
//       lY: number;
//       label: string;
//       ubi_section: string;
//       display: string;
//       maintainment: string;
//       nodeType: {
//         axes_count: string;
//         chart_title: string;
//         chart_xAxis: string;
//         chart_yAxis: string;
//         id: string;
//         label: string;
//         precision: string;
//         short_label: string;
//         unit: string;
//         axes_labels: any;
//         date_format: string;
//       },
//       sid: number;
//       state: number;
//       y_max: number;
//       y_min: number;
//     }[],
//   }[];
// }


